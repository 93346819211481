import React, { useState } from "react";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { StyledMUISelectDataGrid } from "../../../StyledCrossComponents";
import { MenuItem } from "@mui/material";
import { useStorageModal, useStoreModalNotification } from "../../../store";
import { CrossLoader } from "../../CrossComponents";
import { useFetchPut } from "../../../Hooks/useFetchPut";

export const ChangeStateAudCop = ({ props, filter }) => {
  const { setModalAlert } = useStoreModalNotification();
  const [stateValue, setStateValue] = useState(props.row);
  const { setModalCross } = useStorageModal();
  const { SubmitUpdate } = useFetchPut();
  const { data } = useFetchGet("State");
  if (data == null) return null;

  const options =
    filter !== undefined ? data.filter((i) => filter.includes(i.id)) : data;

  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };

  const handleSelectChange = (event, id) => {
    setLoader(true);
    const newStateValue = {
      ...stateValue,
      stateId: event.target.value,
    };

    if (event.target.value <= id) {
      setLoader(false);
      setModalAlert({
        openModal: true,
        message: "Operación no permitida",
        severity: "error",
        variant: "filled",
      });
      return;
    } else {
      setStateValue({ ...stateValue, stateId: event.target.value });
      SubmitUpdate(
        newStateValue,
        `PerPaymentAudCop/${stateValue.paymentReference}`
      ).then((response) => {
        if (!response.ok) {
          setLoader(false);
          setModalAlert({
            openModal: true,
            message: "Error al actualizar el estado del pago",
            severity: "error",
            variant: "filled",
          });
          return;
        }
        setLoader(false);
        setModalAlert({
          openModal: true,
          message: "Estado actualizado",
          severity: "success",
          variant: "filled",
        });
      });
    }
  };

  return (
    <StyledMUISelectDataGrid
      key={props.row.id}
      labelId={`select-label-${props.row.paymentReference}`}
      id={`select-props-${props.row.paymentReference}`}
      value={stateValue.stateId}
      fullWidth
      onChange={(event) => {
        handleSelectChange(event, stateValue.stateId);
      }}
    >
      {options.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.nameState}
        </MenuItem>
      ))}
    </StyledMUISelectDataGrid>
  );
};
