import { useState } from "react";
import { useStoreModalNotification } from "../store";
import { set } from "date-fns";

export const useFetchPost = () => {
  const [dataResp, setDataResp] = useState({});
  const [error, setError] = useState(false);
  const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );

  const handleSubmitLogin = async (dataRequest, apiController) => {
    try {
      const response = await fetch(`${baseUrl}${apiController}`, {
        method: "POST",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataRequest),
      });
      // if (response.status === 401) {
      //   setModalAlert({
      //     openModal: true,
      //     message: "Su sesión ha expirado, por favor vuelva a iniciar sesión",
      //     severity: "error",
      //     variant: "filled",
      //   });
      // }
      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const SubmitFormMedia = async (dataRequest, apiController) => {
    try {
      const response = await fetch(`${baseUrl}${apiController}`, {
        method: "POST",
        credentials: "include",
        body: dataRequest,
      });
      if (response.status === 401) {
        setModalAlert({
          openModal: true,
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión",
          severity: "error",
          variant: "filled",
        });
      }
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const SubmitFormJson = async (dataRequest, apiController) => {
    try {
      const response = await fetch(`${baseUrl}${apiController}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataRequest),
      });

      if (response.status === 401) {
        setModalAlert({
          openModal: true,
          message: "Su sesión ha expirado, por favor vuelva a iniciar sesión",
          severity: "error",
          variant: "filled",
        });
        window.location.href = "/";
      }
      return response;
    } catch (error) {
      setModalAlert({
        openModal: true,
        message: "Error en la solicitud",
        severity: "error",
        variant: "filled",
      });
    }
  };

  return {
    error,
    dataResp,
    SubmitFormMedia,
    SubmitFormJson,
    handleSubmitLogin,
  };
};
