import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { useFetchGet } from "../../../Hooks/useFetchGet";

// const getExchangeRate = (formValues) => {
//   const countryId =  formValues.PaymentCountryOrigenId;
//   console.log(countryId)
//   const { data } = useFetchGet(`Country/${countryId}`);
//   console.log(data)
//   if (data === null) return null;
//   return data;
// };
export const EstimatePayment = ({ formValues, setValues }) => {
  let origenAmount =
    formValues.PaymentCountryOrigenId !== 1
      ? formValues.targetAmount / formValues.exchangeRate
      : formValues.targetAmount * formValues.exchangeRate;

  useEffect(() => {
    setValues("estimatePayment", origenAmount);
  }, [origenAmount]);

  const { data } = useFetchGet(`Country/${formValues.PaymentCountryOrigenId}`);
  if (data === null) return null;

  const userLanguage = navigator.language || navigator.userLanguage;
  return (
    <Grid item xs={6}>
      <Typography variant="h6" gutterBottom>
        {`Usted paga en ${data.currency}:`}
      </Typography>
      <StyledMUIBoxEstimatePayment>
        {origenAmount.toLocaleString(userLanguage, {
          style: "currency",
          currency: data.currency || "AUD",
        })}
      </StyledMUIBoxEstimatePayment>
    </Grid>
  );
};

const StyledMUIBoxEstimatePayment = styled(Box)`
  && {
    text-align: center;
    color: black;
    border-radius: 10px;
    padding: 10px;
    background-color: ${theme.Palette.senary};
  }
`;
