import { FormControl, FormHelperText, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { Controller } from "react-hook-form";
import {
  StyledMUIButtonIcon,
  StyledMUITextField,
} from "../../StyledCrossComponents";
import { useStorageModal } from "../../store";

export const CrossSearchInput = ({
  control,
  errors,
  item,
  formValues,
  setValue,
  ComponentModal,
}) => {
  const setModalCross = useStorageModal((state) => state.setModalCross);
  const handleClick = () => {
    setModalCross({
      openModal: true,
      component: ComponentModal,
      setValue: setValue,
      formValues: formValues,
    });
  };

  return (
    <>
      <Grid item xs={item.xs}>
        <Controller
          name={item.name}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <StyledMUITextField
                {...field}
                label={item.label}
                type={item.type}
                size={item.size}
                sx={item.sx}
                value={field.value || ""}
                disabled={item.disabled}
              />
              {errors[item.name] && (
                <FormHelperText id="my-helper-text" error>
                  {errors[item.name]["message"]}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledMUIButtonIcon
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={handleClick}
        >
          {item.buttonName}
        </StyledMUIButtonIcon>
      </Grid>
    </>
  );
};
