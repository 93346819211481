import React, { useEffect, useRef, useState } from "react";
import { NavBar } from "../Components/NavBar";
import { Grid, Paper } from "@mui/material";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import {
  BodyContainer,
  DivContainer,
  ImageServiceComponent,
  StyledCheckCircleIcon,
  StyledMUIButton,
  StyledMUIGridContainer,
  StyledMUIGridReverse,
  StyledMUIPaperContainer,
  Styledimg,
  Text,
  Titule,
} from "../Styled-components/index";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import KeyIcon from "@mui/icons-material/Key";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { Empresas, Footer } from "../Components";
import ImageComponent from "../Styled-components/StyledImageComponent";
import { service1, service2, service3, service4 } from "../../../assets";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { animationblue } from "../../../assets";
import { animationgreen } from "../../../assets";

export const Service = () => {
  const [animate, setAnimate] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (setAnimate(entry.isIntersecting)) {
          setAnimate(true);
          setTimeout(() => setAnimate(false), 1000);
        }
      },
      { threshold: 0.5 } // Se activa cuando el 50% del elemento es visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <>
      <NavBar />
      <BodyContainer>
        <StyledMUIGridContainer
          id="link-de-pago"
          container
          spacing={2}
          ref={ref}
        >
          <Grid item xs={12} md={5}>
            <Titule>{t("serviceContainer_1.title")}</Titule>
            <Text>{t("serviceContainer_1.description_1")}</Text>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("serviceContainer_1.description_2")}</Text>
            </DivContainer>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("serviceContainer_1.description_3")}</Text>
            </DivContainer>
            <StyledMUIButton
              onClick={() => navigate("/PreRegistro")}
              $width="50%"
            >
              {t("serviceContainer_1.button")}
            </StyledMUIButton>
          </Grid>
          <StyledMUIGridReverse item xs={12} md={7}>
            <ImageComponent src={service1} alt="service" />
          </StyledMUIGridReverse>
          <Styledimg
            key={1}
            $top="70px"
            $left="-400px"
            $animate={animate}
            $rotate="rotate(310deg)"
          >
            <ImageComponent src={animationblue} alt="service" />
          </Styledimg>
          <Styledimg
            key={2}
            $top="300px"
            $left="950px"
            $width="400px"
            $height="400px"
            $animate={animate}
            $rotate="rotate(170deg)"
          >
            <ImageComponent src={animationgreen} alt="service" />
          </Styledimg>
        </StyledMUIGridContainer>
        <StyledMUIGridContainer
          container
          direction="row"
          alignItems="center"
          spacing={2}
          mt={10}
        >
          <Grid item xs={12} md={6}>
            <ImageComponent src={service2} alt="home" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Titule>{t("serviceContainer_2.title")}</Titule>
            <Text>{t("serviceContainer_2.description_1")}</Text>
            <StyledMUIButton
              onClick={() => navigate("/PreRegistro")}
              $width="50%"
            >
              {t("serviceContainer_2.button")}
            </StyledMUIButton>
          </Grid>
        </StyledMUIGridContainer>
        <StyledMUIGridContainer
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={1}
          mt={10}
          sx={{
            backgroundColor: "#DFE8F8",
            borderRadius: "20px",
            padding: "20px",
            overflow: "hidden",
          }}
        >
          <Styledimg
            $top="-120px"
            $left="950px"
            $width="400px"
            $height="400px"
            $rotate="rotate(260deg)"
          >
            <ImageComponent src={animationgreen} alt="service" />
          </Styledimg>
          <Styledimg $top="810px" $left="90px" $rotate="rotate(220deg)">
            <ImageComponent src={animationblue} alt="service" />
          </Styledimg>
          <Grid item xs={12} md={8}>
            <Titule>{t("serviceContainer_3.title")}</Titule>
          </Grid>
          <Grid item xs={12} md={8}>
            <Text>{t("serviceContainer_3.description_1")}</Text>
          </Grid>
          <ImageServiceComponent src={service3} alt="service" />
          <Grid item xs={12} md={8} sx={{ textAlign: "right" }}>
            <StyledMUIButton
              onClick={() => navigate("/PreRegistro")}
              $width="50%"
            >
              {t("serviceContainer_3.button")}
            </StyledMUIButton>
          </Grid>
        </StyledMUIGridContainer>
        <StyledMUIGridContainer
          container
          justifyContent="center"
          mt={5}
          spacing={2}
          sx={{
            backgroundColor: "#FAFAFA",
            borderRadius: "20px",
          }}
        >
          <Grid item xs={12} md={12}>
            <Titule textalign="center">
              {t("serviceContainer_4.title_1")}
            </Titule>
          </Grid>
          <Grid item container justifyContent={"center"} xs={12} md={12}>
            <Text textalign="center" width="40%">
              {t("serviceContainer_4.description_1")}
            </Text>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#FCD53A",
                borderRadius: "25px",
                padding: "20px",
                textAlign: "center",
                height: "195px",
              }}
            >
              <LightbulbIcon fontSize="large" />
              <Titule fontSize="20px">{t("serviceContainer_4.title_2")}</Titule>
              <Text fontSize="15px">
                {t("serviceContainer_4.description_2")}
              </Text>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "25px",
                padding: "20px",
                textAlign: "center",
                height: "195px",
              }}
            >
              <SummarizeIcon fontSize="large" />
              <Titule fontSize="20px">{t("serviceContainer_4.title_3")}</Titule>
              <Text fontSize="15px">
                {t("serviceContainer_4.description_3")}
              </Text>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#E5E5E5",
                borderRadius: "25px",
                padding: "20px",
                textAlign: "center",
                height: "195px",
              }}
            >
              <DashboardIcon fontSize="large" />
              <Titule fontSize="20px">{t("serviceContainer_4.title_4")}</Titule>
              <Text fontSize="15px">
                {t("serviceContainer_4.description_4")}
              </Text>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "25px",
                padding: "20px",
                textAlign: "center",
                height: "195px",
              }}
            >
              <SupportAgentIcon fontSize="large" />
              <Titule textalign="center" fontSize="20px">
                {t("serviceContainer_4.title_5")}
              </Titule>
              <Text textalign="center" fontSize="15px">
                {t("serviceContainer_4.description_5")}
              </Text>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#46BA7C",
                borderRadius: "25px",
                padding: "20px",
                textAlign: "center",
                height: "195px",
              }}
            >
              <KeyIcon fontSize="large" />
              <Titule textalign="center" fontSize="20px">
                {t("serviceContainer_4.title_6")}
              </Titule>
              <Text textalign="center" fontSize="15px">
                {t("serviceContainer_4.description_6")}
              </Text>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "25px",
                padding: "20px",
                textAlign: "center",
                height: "195px",
              }}
            >
              <MoneyOffIcon fontSize="large" />
              <Titule textalign="center" fontSize="20px">
                {t("serviceContainer_4.title_7")}
              </Titule>
              <Text textalign="center" fontSize="15px">
                {t("serviceContainer_4.description_7")}
              </Text>
            </Paper>
          </Grid>
          <Grid item container justifyContent={"center"} xs={12} md={12}>
            <StyledMUIButton
              onClick={() => navigate("/PreRegistro")}
              $width="20%"
            >
              {t("serviceContainer_4.button")}
            </StyledMUIButton>
          </Grid>
        </StyledMUIGridContainer>
        <OtherService />
        <Empresas />
        <Footer />
      </BodyContainer>
    </>
  );
};

const OtherService = () => {
  const { t, i18n } = useTranslation();
  const [animate, setAnimate] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (setAnimate(entry.isIntersecting)) {
          setAnimate(true);
          setTimeout(() => setAnimate(false), 1000);
        }
      },
      { threshold: 0.5 } // Se activa cuando el 50% del elemento es visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <>
      <StyledMUIGridContainer
        container
        alignItems="center"
        justifyContent="center"
        mt={1}
        spacing={5}
        ref={ref}
      >
        <Styledimg
          $top="480px"
          $left="-90px"
          $width="400px"
          $height="400px"
          $rotate="rotate(260deg)"
          $animate={animate}
        >
          <ImageComponent src={animationgreen} alt="service" />
        </Styledimg>
        <Grid item xs={12} md={12}>
          <Titule textalign="center">{t("serviceContainer_5.title_1")}</Titule>
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageComponent src={service4} alt="logo" />
        </Grid>
        <Grid item xs={12} md={5}>
          <StyledMUIPaperContainer sx={{ backgroundColor: "#E5E5E5" }}>
            <Titule fontSize="28px">{t("serviceContainer_5.title_2")}</Titule>
            <Text fontSize="18px">{t("serviceContainer_5.description_1")}</Text>
          </StyledMUIPaperContainer>
          <br />
          <StyledMUIPaperContainer sx={{ backgroundColor: "#DFE8F8" }}>
            <Titule fontSize="28px">{t("serviceContainer_5.title_3")}</Titule>
            <Text fontSize="18px">{t("serviceContainer_5.description_2")}</Text>
          </StyledMUIPaperContainer>
        </Grid>
      </StyledMUIGridContainer>
    </>
  );
};
