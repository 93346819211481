import { create } from "zustand";

export const useUserStore = create((set) => ({
  user: null,

  login: (user) => set({ user }),

  logout: () => set({ user: null }),
}));

// Persistir el estado del usuario en el LocalStorage
useUserStore.subscribe(
  (user) => localStorage.setItem("user", JSON.stringify(user)),
  (state) => JSON.parse(localStorage.getItem("user")) || state
);

export const useLenguageStore = create((set) => ({
  lenguage: "es",
  insurancelinkEs:
    "https://studentinsurancefinder.com/edupayments/customer/home",
  setLenguage: (lenguage, insurancelinkEs) =>
    set({ lenguage, insurancelinkEs }),
}));

const initilaStateModal = {
  openModal: false,
  component: () => {},
  setValue: () => {},
  formValues: {},
};
export const useStorageModal = create((set) => ({
  modalcross: initilaStateModal,
  setModalCross: (update) =>
    set((state) => ({
      modalcross: {
        ...state.modalcross,
        ...update,
      },
    })),
  resetModalCross: () => set({ modalcross: initilaStateModal }),
}));

const initialStateNotification = {
  openModal: false,
  variant: "",
  severity: "error",
  message: "",
};
export const useStoreModalNotification = create((set) => ({
  modalalert: initialStateNotification,
  setModalAlert: (update) =>
    set((state) => ({
      modalalert: {
        ...state.modalalert,
        ...update,
      },
    })),
  resetModalAlert: () => set({ modalalert: initialStateNotification }),
}));

const initialStatepaymentStep = {
  exchangeAgency: 0,
  acronym: "",
  paymentTypeDescription: "",
  paymentOptionId: 0,
  payoutMethodName: "",
  tagetAmountWithInsurance: 0.0,
  estimatePaymentWithInsurance: 0.0,
  offeringInsurance: {},
  offeringInsuranceDateStart: "",
  offeringInsuranceDateEnd: "",
  offeringId: 0,
};

export const useStorePaymentStep = create((set) => ({
  paymentStepState: initialStatepaymentStep,
  setPaymentStep: (update) =>
    set((state) => ({
      paymentStepState: {
        ...state.paymentStepState,
        ...update,
      },
    })),
  resetPaymentStep: () =>
    set((state) => ({
      paymentStepState: {
        ...state.paymentStepState,
        exchangeAgency: 0,
        acronym: "",
        paymentTypeDescription: "",
        paymentOptionId: 0,
        payoutMethodName: "",
        tagetAmountWithInsurance: 0,
        estimatePaymentWithInsurance: 0,
        offeringInsurance: {},
        offeringInsuranceDateStart: "",
        offeringInsuranceDateEnd: "",
      },
    })),
}));

const initialStateSettle = {
  agencyPayment: {
    errors: {},
    agencyId: 0,
    payoutMethodName: "",
    senderAmount: 0,
    senderCurrency: "",
    beneficiaryCurrency: "",
    bsb: "",
    accountNumber: "",
    firstName: "",
    lastName: "",
    organizationReferenceId: "",
    companyName: "",
    typePersonId: "",
    paymentReference: "",
  },
  beneficiaryPayment: {
    agencyId: 0,
    errors: {},
    payoutMethodName: "",
    senderAmount: 0,
    senderCurrency: "",
    beneficiaryCurrency: "",
    bsb: "",
    accountNumber: "",
    firstName: "",
    lastName: "",
    organizationReferenceId: "",
    companyName: "",
    typePersonId: "",
    paymentReference: "",
  },
};
export const useStoreSeattlePaymentStep = create((set) => ({
  seattlePaymentStepState: initialStateSettle,
  setSeattlePaymentStep: (update) =>
    set((state) => ({
      seattlePaymentStepState: {
        agencyPayment: {
          ...state.seattlePaymentStepState.agencyPayment,
          ...update.agencyPayment,
        },
        beneficiaryPayment: {
          ...state.seattlePaymentStepState.beneficiaryPayment,
          ...update.beneficiaryPayment,
        },
      },
    })),
  resetSeattlePaymentStep: () =>
    set({ seattlePaymentStepState: initialStateSettle }),
  resetSeattleAgencyErrors: () =>
    set((state) => ({
      seattlePaymentStepState: {
        ...state.seattlePaymentStepState,
        agencyPayment: {
          ...state.seattlePaymentStepState.agencyPayment,
          errors: {},
        },
      },
    })),
  resetSeattleBeneficiaryErrors: () =>
    set((state) => ({
      seattlePaymentStepState: {
        ...state.seattlePaymentStepState,
        beneficiaryPayment: {
          ...state.seattlePaymentStepState.beneficiaryPayment,
          errors: {},
        },
      },
    })),
}));

const initialStateFromPayment = {
  fromPayment: "COP",
};

export const useStoreFromPayment = create((set) => ({
  fromPaymentState: initialStateFromPayment,
  setFromPayment: (update) =>
    set((state) => ({
      fromPaymentState: {
        ...state.fromPaymentState,
        ...update,
      },
    })),
  resetFromPayment: () => set({ fromPaymentState: initialStateFromPayment }),
}));

const initialStateLedgerAccount = {};

export const useStoreLedgerAccount = create((set) => ({
  ledgerAccountState: initialStateLedgerAccount,
  setLedgerAccount: (update) =>
    set((state) => ({
      ledgerAccountState: {
        ...state.ledgerAccountState,
        ...update,
      },
    })),

  resetLedgerAccount: () =>
    set({ ledgerAccountState: initialStateLedgerAccount }),
}));
