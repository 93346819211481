
import { Box } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";

export const StyledMUIBoxRate = styled(Box)`
  && {
    text-align: center;
    color: white;
    border-radius: 10px;
    padding: 10px;
    background-color: ${props => props.backgroundcolor || theme.Palette.tertiary} 
  }
`;