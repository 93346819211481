import { Box } from "@mui/material";
import styled from "styled-components";
import theme from "../theme";
import { PropaneSharp } from "@mui/icons-material";

export const StyledMUIBoxContainerForm = styled(Box)`
  && {
    border: 0px solid;
    border-color: ${theme.Palette.octonary};
    border-radius: 25px;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : theme.Palette.nonary};
    padding: 20px;
    width: ${(props) => (props.width ? props.width : "50%")};
    //margin-top: ${(props) => (props.marginTop ? props.marginTop : "0%")};
    //box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledMUIBoxContainerCalc = styled(Box)`
  && {
    border: none;
    border-color: ${theme.Palette.octonary};
    border-radius: 10px;
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : theme.Palette.quinary};
    padding: 20px;
    //width: ${(props) => (props.width ? props.width : "100%")};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "5%")};
    //box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  }
`;
