import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { useUserStore } from "../../../store";
import ListItems from "../Components/ListItems";
import ListItem from "@mui/material/ListItem";
import theme from "../../../theme";
import ImageComponent from "../Styled-components/StyledImageComponent";
import { navLogo } from "../../../assets";
import { fontSize } from "@mui/system";
const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#fff",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    // position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#434749",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  typography: {
    fontFamily: theme.Font.primary,
  },
});

export default function Dashboard() {
  const { user } = useUserStore();
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Grid
              container
              spacing={3}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Typography
                  component={"div"}
                  color={theme.Palette.secondary}
                  backgroundColor={theme.Palette.senary}
                  borderRadius={"25px"}
                  fontSize={"12px"}
                  fontWeight={theme.FontWeight.bold}
                  p={1}
                >
                  {user.agency === null
                    ? "Edupayments"
                    : `Agencia: ${user.agency.agencyName}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  component={"div"}
                  color={theme.Palette.secondary}
                  backgroundColor={theme.Palette.senary}
                  borderRadius={"25px"}
                  fontSize={"12px"}
                  fontWeight={theme.FontWeight.bold}
                  p={1}
                >
                  {`Usuario: ${user.email}`}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              // px: [1],
            }}
          >
            <ImageComponent src={navLogo} alt="logo" />
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <ListItems isAgency={user.checkIsAgency} sx={{ fontSize: "12px" }} />
          <Divider />
          <ListItem disablePadding sx={{ alignSelf: "flex-end" }}>
            <Button
              size="small"
              fullWidth
              sx={{ color: theme.Palette.quinary }}
              onClick={() => {
                localStorage.removeItem("user");
                window.location.href = "/";
              }}
            >
              Cerrar Sesión
            </Button>
          </ListItem>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: "#fff",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            ml: { sm: `+${drawerWidth}px` },
          }}
        >
          <Container
            maxWidth="lg"
            sx={{ mt: 4, mb: 4, fontFamily: theme.fontFamily }}
          >
            <Grid container spacing={3}>
              <Outlet />
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
