import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { personas1, personas2 } from "../../../assets";
import theme from "../../../theme";
import { Empresas, Footer, NavBar } from "../Components";
import { SeguroMedico } from "../Components/SeguroMedico";
import {
  BodyContainer,
  DivContainer,
  StyledCheckCircleIcon,
  StyledImageComponent,
  StyledMUIButton,
  StyledMUIGridContainer,
  StyledMUIGridImageContainer,
  StyledMUIGridReverse,
  Text,
  Titule,
} from "../Styled-components";
import { useNavigate } from "react-router-dom";

export const Personas = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.Palette.quinary,
          fontFamily: theme.Font.primary,
          padding: "20px",
        }}
      >
        <NavBar backgroundcolor={theme.Palette.quinary} />
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth: theme.Breakpoint.xlarge }}
        >
          <Grid item xs={12} sm={5}>
            <Titule>{t("personsContainer_1.title_1")}</Titule>
            <Text>{t("personsContainer_1.description_1")}</Text>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("personsContainer_1.description_2")}</Text>
            </DivContainer>
            <DivContainer>
              <StyledCheckCircleIcon />
              <Text>{t("personsContainer_1.description_3")}</Text>
            </DivContainer>
            <StyledMUIButton
              onClick={() => navigate("/RegistrationPersons")}
              $width={"50%"}
            >
              {t("personsContainer_1.button")}
            </StyledMUIButton>
          </Grid>
          <StyledMUIGridReverse item xs={12} sm={6} justifyContent="center">
            <StyledImageComponent src={personas1} alt="edupayments" />
          </StyledMUIGridReverse>
        </Grid>
      </Grid>
      <BodyContainer>
        <StyledMUIGridContainer
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <StyledMUIGridImageContainer item xs={12} sm={6}>
            <StyledImageComponent src={personas2} alt="edupayments" />
          </StyledMUIGridImageContainer>
          <Grid item xs={12} sm={5}>
            <Titule>{t("personsContainer_2.title_1")}</Titule>
            <Text>{t("personsContainer_2.description_1")}</Text>

            {/* <StyledMUIButton>{t("personsContainer_2.button")}</StyledMUIButton> */}
          </Grid>
        </StyledMUIGridContainer>
        <StyledMUIGridContainer
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ backgroundColor: theme.Palette.nonary }}
        >
          <Grid item xs={12} sm={6}>
            <Titule>{t("personsContainer_3.title_1")}</Titule>
            <Text>{t("personsContainer_3.description_1")}</Text>
          </Grid>
          <Grid item xs={12} sm={3}>
            {/* <StyledMUIButton>{t("personsContainer_3.button")}</StyledMUIButton> */}
          </Grid>
        </StyledMUIGridContainer>
        <SeguroMedico />
        <Empresas />
        <Footer />
      </BodyContainer>
    </>
  );
};
