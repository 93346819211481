import React from "react";
import RegisterSteps from "../Components/RegisterSteps";

export const RegisterAgency = () => {
  return (
    <>
      <RegisterSteps />
    </>
  );
};
