import React from "react";
import { getPaymentPerDataSteps } from "../Helpers/getPaymentPerDataSteps";
import {
  useStoreFromPayment,
  useStoreLedgerAccount,
  useUserStore,
} from "../../../store";
import { Box, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { PersonsPaymentForm } from "./PersonsPaymentForm";
import { useNavigate } from "react-router-dom";
import { PersonLedgerAccount } from "./PersonLedgerAccount";

export const PersonPaymentSteps = () => {
  const { user } = useUserStore();
  const { ledgerAccountState } = useStoreLedgerAccount();
  const { fromPaymentState } = useStoreFromPayment();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const dataSteps = getPaymentPerDataSteps(
    user,
    fromPaymentState.fromPayment,
    ledgerAccountState
  );
  const navigate = useNavigate();

  const isStepOptional = (step) => {
    return step === 5;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    navigate("/Dashboard");
  };

  return (
    <Grid container alignItems="center" justifyContent="center">
      <PersonLedgerAccount />
      <Box mt={5} sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            "& .MuiStep-root": {
              minWidth: "1px",
            },
            "& .MuiStepLabel-label": {
              fontSize: "0.675rem",
            },
          }}
        >
          {dataSteps.map((item, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={item.label} {...stepProps}>
                <StepLabel {...labelProps}>{item.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === dataSteps.length ? (
          handleReset()
        ) : (
          <React.Fragment>
            <PersonsPaymentForm
              handleNext={handleNext}
              handleBack={handleBack}
              handleReset={handleReset}
              activeStep={activeStep}
              dataStepActive={dataSteps[activeStep]}
              dataSteps={dataSteps}
            />
          </React.Fragment>
        )}
      </Box>
    </Grid>
    //</Grid>
  );
};
