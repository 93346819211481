import React from "react";
import {
  useStorageModal,
  useStoreModalNotification,
  useUserStore,
} from "../../../store";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import { StyledGridActionsCellItem } from "../../../StyledCrossComponents";
import { DetailedPaymentSummary, SelectState } from "../../Payments/Components";
import { CrossDataGrid } from "../../CrossComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export const PaymentList = () => {
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );
  const { user } = useUserStore();
  const statePaymentlist = useFetchGet("State");

  const columns = [
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      renderCell: (params) => [
        <StyledGridActionsCellItem
          label={"Ver"}
          icon={<AddCircleIcon />}
          key={`read-${params.row.id}`}
          onClick={() => handleSelectRow(params.row)}
        />,
      ],
    },
    {
      field: "paymentReference",
      headerName: "Referencia de pago",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "paymentDate",
      headerName: "Fecha de pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.paymentDate);
        return date.toLocaleDateString(userLanguage);
      },
    },
    {
      field: "exchangeRate",
      headerName: "Tasa de cambio",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.exchangeRate.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </>
        );
      },
    },
    {
      field: "estimatePayment",
      headerName: "Monto de pago COP",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.estimatePayment.toLocaleString(userLanguage, {
              style: "currency",
              currency: "COP",
            })}
          </>
        );
      },
    },
    {
      field: "targetAmount",
      headerName: "Monto de pago",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.targetAmount.toLocaleString(userLanguage, {
              style: "currency",
              currency: params.row.currency || "AUD",
            })}
          </>
        );
      },
    },
    {
      field: "stateId",
      headerName: "Estado",
      headerAlign: "center",
      align: "left",
      flex: 2,
      renderCell: (params) => (
        <SelectState
          props={params}
          statePaymentlist={statePaymentlist}
          setModalAlert={setModalAlert}
        />
      ),
    },
  ];
  const setModalCross = useStorageModal((state) => state.setModalCross);
  const handleSelectRow = (row) => {
    setModalCross({
      openModal: true,
      component: () => <DetailedPaymentSummary rowData={row} />,
      setValue: null,
      formValues: null,
    });
  };

  const { data } = useFetchGet(`Payments`);

  return (
    <>
      <CrossDataGrid
        title=""
        columns={columns}
        data={data}
        rowId={"paymentReference"}
        sortModel={sortModel}
      />
    </>
  );
};

const userLanguage = navigator.language || navigator.userLanguage;

const sortModel = [
  {
    field: "paymentDate",
    sort: "desc",
  },
];
