import React from "react";
import { Footer, NavBar } from "../Components";
import { BodyContainer, StyledMUIGridContainer } from "../Styled-components";
import { CalculatorForm } from "../Components/CalculatorForm";

export const Calcualtor = () => {
  return (
    <>
      <NavBar />
      <BodyContainer>
        <CalculatorForm />
        <Footer />
      </BodyContainer>
    </>
  );
};
