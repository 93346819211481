import { Grid } from "@mui/material";
import React from "react";
import { FormPreRegistro, NavBar } from "../Components";
import { Text, Titule } from "../Styled-components";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";

export const PreRegistro = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.Palette.senary,
          fontFamily: theme.Font.primary,
          padding: "20px",
        }}
      >
        <NavBar backgroundcolor={theme.Palette.senary} />
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          position={"relative"}
          height={"400px"}
          sx={{ maxWidth: theme.Breakpoint.xlarge }}
        >
          <Grid item textAlign={"center"} width={"698px"}>
            <Titule>{t("preregister.title_1")}</Titule>
            <Text>
              {t("preregister.description_1")}
              {t("preregister.description_2")}
            </Text>
            <FormPreRegistro />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} mt={60} />
    </>
  );
};
